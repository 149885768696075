
$(function() {

  var defOri = null;

  // DeviceOrientation Event
  //if (DeviceOrientationEvent in window) {
  window.addEventListener("deviceorientation", deviceorientationHandler);
  //}

  // ジャイロセンサーの値が変化
  function deviceorientationHandler(event) {
    // X軸
    var b = event.beta;
    // Y軸
    var g = event.gamma;
    // Z軸
    var a = event.alpha;

    if(!defOri){
      defOri = {b:b, g:g, a:a}
    }else{
      var rx = (b-defOri.b);
      var ry = -(g-defOri.g);
      var rz = (a-defOri.a);

      $(".logo").css({transform:'rotateX('+rx+'deg) rotateY('+ry+'deg) rotateZ('+rz+'deg)'});
      //$(".logo_r-x").css({transform:'rotateX('+rx+'deg)'});
      //$(".logo_r-y").css({transform:'rotateY('+ry+'deg)'});
      //$(".logo_r-z").css({transform:'rotateZ('+rz+'deg)'});
    }

  }
});
